import React, { useEffect, useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import clsx from 'clsx';
import { Container } from './Container'
import backgroundImage from './images/background-features.jpg';
import screenshotBrowser1 from './images/screenshots/radiata_browser_screenshot_2.png';
import screenshotProcessing from './images/screenshots/aws_step_functions_3x.png';
import screenshotDatasets from './images/screenshots/datasets.png';

const features = [
  {
    title: 'Immersive Analysis',
    description:
      "Do interactive neuroimaging biomarker work in the browser with no installs. Data science, visualization, neuroinformatics, and AI at your fingertips.",
    image: screenshotBrowser1,
  },
  {
    title: 'Easy Automated Processing',
    description:
      "Drag and drop to process all your structural and functional MRI brain scans in two hours. Run  modern industry-standard pipelines and host your data securely in the cloud. Automatic QC and harmonization with public datasets.",
    image: screenshotProcessing,
  },
  {
    title: 'Instant Access to Data',
    description:
      "Instant free access to processed public neuroimaging datasets like OASIS, NKI-RS, DLBS, IXI, ABIDE, GSP, 1000FC, HBN, and BrainLat completely free forever. Our shared database has 20K+ MRI scans and growing, across the lifespan and brain health spectrum.",
    image: screenshotDatasets,
  },
];

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState('horizontal');

  useEffect(() => {
    let lgMediaQuery = window.matchMedia('(min-width: 1024px)');

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal');
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener('change', onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange);
    };
  }, []);

  return (
    <section
      id="features"
      aria-label="Radiata features"
      className="relative overflow-hidden"
    >
      
      <div className="bg-dark-blue">
      {/* <img
        className="absolute max-w-none"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
        loading="lazy"
      /> */}
      
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
        <div className="p-6"></div>
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl mt-10">
            Accelerate your MRI image processing, shared dataset access, and data science.
          </h2>
        </div>
        <TabGroup
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === 'vertical'}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <TabList className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={clsx(
                        'group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6' ,
                        selectedIndex === featureIndex
                          ? 'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10'
                          : 'hover:bg-white/10 lg:hover:bg-white/5'
                      )}
                    >
                      <h3>
                      <Tab
                        className={clsx(
                            'font-display text-2xl outline-none focus:outline-none focus:ring-0 focus-visible:outline-none focus-visible:ring-0  bg-transparent border-none',
                            selectedIndex === featureIndex
                            ? 'text-blue-600 lg:text-white bg-blue-600'
                            : 'text-blue-100 hover:text-white lg:text-white bg-transparent'
                        )}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={clsx(
                          'mt-2 hidden text-xl lg:block',
                          selectedIndex === featureIndex
                            ? 'text-white'
                            : 'text-blue-100 group-hover:text-white'
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </TabList>
              </div>
              <TabPanels className="lg:col-span-7">
                {features.map((feature) => (
                  <TabPanel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                    <img
                    className="h-[50rem] w-auto object-contain"
                    src={feature.image}
                    alt=""
                    priority
                    sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                    />
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            </>
          )}
        </TabGroup>
        <div className="p-6"></div>
      </Container>
      </div>
      
    </section>
  );
}