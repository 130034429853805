import { useId } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import clsx from 'clsx'
import { Container } from './Container'
import screenshotLatentSpace from './images/screenshots/latent_space.png'
import screenshotModelDiagnostics from './images/screenshots/model_diagnostics.png'
import screenshotBrainViewer from './images/screenshots/brain_viewer.png'

const features = [
  {
    name: 'Latent space',
    summary: 'A 10,000 foot view for your brain scans.',
    description:
      'See where any scan lies within a population based on brain structure. Stratify subjects based on data-driven subtypes.',
    image: screenshotLatentSpace,
    icon: function ScatterplotIcon() {
        let id = useId();
        return (
          <>
            <defs>
              <linearGradient
                id={id}
                x1="0"
                y1="0"
                x2="24"
                y2="24"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".194" stopColor="#fff" />
                <stop offset={1} stopColor="#42A5F5" />
              </linearGradient>
            </defs>
            <circle cx="4" cy="20" r="1.5" fill={`url(#${id})`} />
            <circle cx="6" cy="14" r="1.2" fill={`url(#${id})`} />
            <circle cx="8" cy="8" r="2" fill={`url(#${id})`} />
            <circle cx="10" cy="16" r="1.8" fill={`url(#${id})`} />
            <circle cx="12" cy="6" r="2" fill={`url(#${id})`} />
            <circle cx="14" cy="20" r="1.5" fill={`url(#${id})`} />
            <circle cx="16" cy="10" r="1.8" fill={`url(#${id})`} />
            <circle cx="18" cy="12" r="2.5" fill={`url(#${id})`} />
            <circle cx="20" cy="18" r="1.5" fill={`url(#${id})`} />
            <circle cx="22" cy="16" r="1.2" fill={`url(#${id})`} />
            <circle cx="24" cy="8" r="1.8" fill={`url(#${id})`} />
          </>
        );
      },
  },
  {
    name: 'Model diagnostics',
    summary:
      'Validate your models with easy integrated diagnostics.',
    description:
      'Interactive Confusion matrices, ROC curves, and Regression + Residual plots, all linked to other modules for convenient model analysis.',
    image: screenshotModelDiagnostics,
    icon: function ReportingIcon() {
        let id = useId()
        return (
          <>
            <defs>
              <linearGradient
                id={id}
                x1="11.5"
                y1={18}
                x2={36}
                y2="15.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".194" stopColor="#fff" />
                <stop offset={1} stopColor="#6692F1" />
              </linearGradient>
            </defs>
            <path
              d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5"
              stroke={`url(#${id})`}
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </>
        )
      },
  },
  {
    name: 'Brain viewer',
    summary:
      'Bring it back to the brain.',
    description:
      'View scans and feature maps. Apply any atlas or neuromap (networks, neurotransmitters, gene expression) to make sure the patterns you find make biological sense.',
    image: screenshotBrainViewer,
    icon: function ContactsIcon() {
      return (
        <>
          <path
            opacity=".5"
            d="M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75.357.421.993.419 1.384.028.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z"
            fill="#fff"
          />
          <path
            d="M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5 1.343 3.5 3 3.5 3-1.567 3-3.5Z"
            fill="#fff"
          />
        </>
      )
    },
  },
]

function Feature({ feature, isActive, className, ...props }) {
  return (
    <div
      className={clsx(className, !isActive && 'opacity-75 hover:opacity-100 bg-custom-gray')}
      {...props}
    >
      <div
        className={clsx(
          'w-9 rounded-lg',
          isActive ? 'bg-teal-green' : 'bg-teal-green/70',
        )}
      >
        <svg aria-hidden="true" className="h-9 w-9" fill="none">
          <feature.icon />
        </svg>
      </div>
      <h3
        className={clsx(
          'mt-6 text-sm font-medium',
          isActive ? 'text-teal-green' : 'text-slate-600',
        )}
      >
        {feature.name}
      </h3>
      <p className="mt-2 font-display text-xl text-slate-900">
        {feature.summary}
      </p>
      <p className="mt-4 text-lg text-slate-600">{feature.description}</p>
    </div>
  )
}

function FeaturesMobile() {
  return (
    <div className="mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.summary}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" />
            <div className="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
              <img
                className="w-full"
                src={feature.image}
                alt=""
                sizes="52.75rem"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

function FeaturesDesktop() {
  return (
    <TabGroup className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <TabList className="grid grid-cols-3 gap-x-8">
            {features.map((feature, featureIndex) => (
              <Feature
                key={feature.summary}
                feature={{
                  ...feature,
                  name: (
                    <Tab className="ui-not-focus-visible:outline-none focus:outline-none bg-transparent border-none p-0 text-2xl text-inherit cursor-pointer">
                      <span className="absolute inset-0" />
                      {feature.name}
                    </Tab>
                  ),
                }}
                isActive={featureIndex === selectedIndex}
                className="relative"
              />
            ))}
          </TabList>
          <TabPanels className="relative bg-trans mt-20 outline-none overflow-hidden rounded-4xl border border-transparent outline outline-transparent bg-slate-200 px-14 py-16 xl:px-16">
            <div className="-mx-5 flex outline-none">
              {features.map((feature, featureIndex) => (
                <TabPanel
                  static
                  key={feature.summary}
                  className={clsx(
                    'px-5 transition duration-500 ease-in-out ui-not-focus-visible:outline-none',
                    featureIndex !== selectedIndex && 'opacity-60',
                  )}
                  style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                  aria-hidden={featureIndex !== selectedIndex}
                >
                  <div className="w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-black">
                    <img
                      className="w-full"
                      src={feature.image}
                      alt=""
                      sizes="52.75rem"
                    />
                  </div>
                </TabPanel>
              ))}
            </div>
            <div className="pointer-events-none absolute rounded-4xl ring-1  ring-slate-900/10" />
          </TabPanels>
        </>
      )}
    </TabGroup>
  )
}

export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features"
      className="pt-8 pb-8 sm:pt-12 sm:pb-0 bg-custom-gray"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center bg">
          <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
            Discover new patterns with ease.
          </h2>
          <p className="mt-4 text-2xl tracking-tight text-slate-700">
            The MxD browser makes discovery and validation faster, more intuitive, and easier to share.
          </p>
        </div>
        <FeaturesMobile />
        <FeaturesDesktop />
      </Container>
    </section>
  )
}